<!-- 
	@component
	
	LIGHTWEIGHT AREA CHART
	
	@prop {string} symbol - The symbol to fetch historical data for.
	@prop {DeepPartial<TimeChartOptions>} overrides - Overrides for the chart options.
	@prop {number} width - The width of the chart.
	@prop {number} aspect - The aspect ratio of the chart.
	@prop {string} border - The border of the chart.
	@prop {string} padding - The padding of the chart.
	@prop {string} rounded - The border radius of the chart.
	@prop {string} hover - The hover state of the chart.
	@prop {string} classes - Additional classes to apply to the chart container.

-->

<script context="module" lang="ts">
	const TIMEZONE = 'UTC';
</script>

<script lang="ts">
	import { browser } from '$app/environment';
	import { page } from '$app/stores';
	import { createQuery } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import { type TDTypes } from '@tickrr/twelvedata';
	import extend from 'just-extend';
	import {
		type DeepPartial,
		type IChartApi,
		type TimeChartOptions,
		createChart
	} from 'lightweight-charts';
	import _ from 'lodash';
	import { onMount } from 'svelte';

	import { tippy } from '../../../../actions/tippy.ts';
	import { getChartURL } from '../../../../utils/getChartURL.ts';
	import InstrumentLogo from '../../elements/images/TwicInstrumentLogo.svelte';
	import LoaderTrio from '../../elements/loaders/LoaderTrio.svelte';
	import { type LWAreaSeries, generateChartColor, lwFormat } from './common.ts';
	import { MINI_AREA_CHART_CONFIG, SERIES_CONFIGS } from './configs.ts';
	import { toAreaSeries } from './series.ts';

	// Period shown === 1 year
	const INTERVAL = '15min' as const;
	const INTERVALS_IN_PERIOD = 365; // max allowed

	export let symbol: string;
	export let exchangeName: null | string = null;
	export let href: null | string = null;
	export let overrides: DeepPartial<TimeChartOptions> = {};
	export let width = 'w-full max-w-xs';
	export let height = ''; // CANNOT BE USED IN CONJUNCTION WITH `aspect`
	export let aspect: string = 'aspect-video'; // CANNOT BE USED IN CONJUNCTION WITH `height`
	export let border = 'border border-base';
	export let padding = 'pb-2'; // Because chart gets so close to bottom of container
	export let rounded = 'rounded-token';
	export let hover = 'hover:bg-component hover:border-base';
	export let classes = '';

	let chart: IChartApi | undefined = undefined;
	let chartContainerEl: HTMLDivElement | null = null; // Null until component mounts
	let series: LWAreaSeries | null = null; // Null until component mounts
	let rawData: TDTypes.TDValidatedTimeSeries = [];
	let latestPriceInSeries: number = 0;
	let firstPriceInSeries: number = 0;
	let seriesPriceDiff: number = 0;
	let seriesPriceDiffPct: number = 0;

	$: timeSeriesQuery = createQuery({
		enabled: !!browser, // We need to wait until chart is mounted so onSuccess side effects can run
		onError: (error) => logger.error('Error fetching chart data:', error),
		queryFn: async () => {
			const { values } = await trpc($page).ticker.fetchTimeSeries.query({
				interval: INTERVAL,
				order: 'ASC', // LightWeightCharts will throw if data is not ifetchAreaSerieser
				outputsize: INTERVALS_IN_PERIOD,
				symbol
			});
			return values;
		},
		queryKey: ['symbol:mini-chart:timeseries', symbol],
		staleTime: 3000 * 60 * 5 // 5 minutes
	});

	$: instrumentMetadataQuery = createQuery({
		enabled: !!browser,
		queryFn: async () => {
			return await trpc($page).ticker.fetchInstrument.query({
				exchangeName,
				symbol
			});
		},
		queryKey: ['symbol:instrument', symbol],
		staleTime: 3000 * 60 * 5 // 3 minutes
	});

	function setChartData(ts: TDTypes.TDValidatedTimeSeries) {
		if (ts.length === 0) {
			logger.warn('No data was returned from the API.');
			return;
		}

		rawData = ts;

		if (!chart) {
			logger.warn('Chart is not defined (likely not mounted). Cannot set chart data.');
			return;
		}

		if (!series) {
			logger.warn('Price series is not defined. Cannot set chart data.');
			return;
		}

		series?.setData(toAreaSeries(ts));

		latestPriceInSeries = _.last(rawData)?.close ?? 0;
		firstPriceInSeries = _.first(rawData)?.close ?? 0;
		seriesPriceDiff = latestPriceInSeries - firstPriceInSeries;
		seriesPriceDiffPct = (seriesPriceDiff / firstPriceInSeries) * 100;

		chart.timeScale().fitContent();
		series?.applyOptions({
			bottomColor: 'transparent',
			lineColor:
				seriesPriceDiff > 0
					? generateChartColor('--color-success-500', 1.0)
					: generateChartColor('--color-error-500', 1.0),
			topColor:
				seriesPriceDiff > 0
					? generateChartColor('--color-success-500', 0.4)
					: generateChartColor('--color-error-500', 0.4)
		});
	}

	function addPriceSeries(lwChart: IChartApi) {
		series = lwChart.addAreaSeries(SERIES_CONFIGS.area);
		series.priceScale().applyOptions({
			scaleMargins: {
				bottom: 0.1,
				top: 0 // highest point of the series will be 10% away from the top
			}
		});
	}

	onMount(() => {
		if (!chartContainerEl) {
			throw new Error('Container element is not defined. Cannot create chart.');
		}

		chart = createChart(chartContainerEl, extend(MINI_AREA_CHART_CONFIG.chart, overrides));
		addPriceSeries(chart);

		if (!series) {
			throw new Error('Series is not defined. Cannot update price lines.');
		}

		chart.timeScale().fitContent();

		// This must be invoked to ensure the chartData is set _EVERY_time the component mounts.
		// The reactive statement below will _ONLY_ run on the first mount. Thereafter, the query is cached
		// and not run again, so the reactive statement will not run.
		setChartData($timeSeriesQuery.data ?? []);
	});

	// This must be done with a reactive statement, rather than `onSuccess` in the query itself.
	// The `onSuccess` method will only run the FIRST time that an interval is chosen. Otherwise,
	// the data is loaded directly from the cache, and the `onSuccess` method is not invoked.
	$: setChartData($timeSeriesQuery.data ?? []);
	$: instrument = $instrumentMetadataQuery.data;
</script>

<a
	data-is-error={$timeSeriesQuery.isError}
	data-is-loading={$timeSeriesQuery.isLoading}
	data-is-success={$timeSeriesQuery.isSuccess}
	data-symbol={symbol}
	data-testid="lw-mini-chart"
	data-timezone={TIMEZONE}
	href={href ?? getChartURL({ exchangeName, symbol })}
	class="flex {aspect} flex-col overflow-hidden {width} {height} {border} {padding} {rounded} {classes} {hover}"
	use:tippy={{
		allowHTML: true,
		content: `<b>${symbol}</b>: ${instrument?.name ?? 'Loading...'}`,
		placement: 'top'
	}}
>
	<!-- HEADER -->
	<header data-testid="lw-mini-chart__header" class="h-fit space-y-2 p-2 pb-0">
		<div class="flex gap-x-2">
			<InstrumentLogo
				classes="shrink-0"
				height="h-9"
				rounded="rounded-token"
				{symbol}
				width="w-9"
			/>
			<div>
				<span class="line-clamp-1 text-sm font-bold text-surface-50">
					{symbol}
				</span>
				<span class="line-clamp-1 text-xs">
					{instrument?.name ?? 'Loading...'} &#183; 5D
				</span>
			</div>
		</div>

		<div>
			<span class="text-lg font-bold text-surface-50">
				{lwFormat('price', latestPriceInSeries)}
			</span>
			<span
				class:text-error-500={seriesPriceDiff < 0}
				class:text-success-500={seriesPriceDiff > 0}
			>
				({lwFormat('percent_change', seriesPriceDiffPct / 100)})
			</span>
		</div>
	</header>

	<!-- CHART -->
	<div
		bind:this={chartContainerEl}
		data-testid="lw-mini-chart__chart"
		class="pointer-events-none relative h-full w-full"
		{...$$restProps}
	>
		<!-- 
		ALTERNATIVE VIEWS
		These views are displayed when the chart is loading, has no data, or has an error.
		-->
		{#if $timeSeriesQuery.isLoading}
			<div class="absolute inset-0 z-10 flex w-full items-center justify-center p-4">
				<LoaderTrio color="surface" size={20} />
			</div>
		{:else if rawData.length === 0}
			<div
				class="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-surface-900 p-4"
			>
				<p>No data is available for this period.</p>
			</div>
		{:else if $timeSeriesQuery.isError}
			<div
				class="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-surface-900 p-4"
			>
				<div class="max-w-sm text-center">
					<p>An error occurred while fetching the data.</p>
					<p>
						Please try refreshing the page or report this to our support team at
						<a href="mailto:support@tickrr.io" class="anchor">support@tickrr.io</a>.
					</p>
				</div>
			</div>
		{/if}
	</div>
</a>
